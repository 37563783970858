import React from 'react';
import { Classes, Tooltip2 } from "@blueprintjs/popover2";
const jsxContent = <em>Dữ liệu hạn chế truy cập theo phân quyền</em>;

export const dateConvert = (datetime) => {
  if (datetime) {
    let newDate = datetime;
    let date = ('0' + newDate.getDate()).slice(-2);
    let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    let year = newDate.getFullYear();
    const day = date + '/' + month + '/' + year;
    return day;
  }

  return null;
};
export const stringToDate = (datetimestr) => {
  if (datetimestr) {
    let date = datetimestr.slice(0, 2)
    let month = datetimestr.slice(3, 5)
    let year = datetimestr.slice(6, 11)
    return new Date(year, month, date);
  }

  return null;
};
export const dateConvertToNumber = (datetime, hour = "00") => {
  if (datetime) {
    let newDate = datetime;
    let date = ('0' + newDate.getDate()).slice(-2);
    let month = ('0' + (newDate.getMonth() + 1)).slice(-2);
    let year = newDate.getFullYear();
    const day = year + month + date + hour;
    return day;
  }
  return null;
};

export const getPositionXY = (element) => {
  var rect = document.getElementById(element).getBoundingClientRect();
  return {
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left
  };
};

export const deptOfObject = (object) => {
  var level = 1;
  for (var key in object) {
    if (!object.hasOwnProperty(key)) continue;

    if (typeof object[key] == 'object') {
      var depth = deptOfObject(object[key]) + 1;
      level = Math.max(depth, level);
    }
  }
  return level;
};
export const maxLengthOfArray = (object) => {
  var length = 1;
  for (var key in object) {
    if (!object[key].length) continue;
    if (object[key].length > length) {
      length = object[key].length;
    }
  }
  return length;
};
export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const numberOfDaysBetweenTwoDates = (fromDate, toDate) => {
  let date1 = new Date(fromDate);
  let date2 = new Date(toDate);
  let resultInTime = date2.getTime() - date1.getTime();
  let resultNumber = resultInTime / 86400000;
  resultNumber = Math.round(resultNumber, 0).toString();
  return resultNumber;
};
export const numberOfDaysBetweenTwoDatesTable = (fromDate, toDate) => {
  let date1 = new Date(fromDate);
  let date2 = new Date(toDate);
  let resultInTime = date2.getTime() - date1.getTime();
  let resultNumber = resultInTime / 86400000;
  resultNumber = Math.round(resultNumber + 1, 0).toString();
  return resultNumber;
};
export const recursiveObjectToArray = (child) => {
  let cssTransitionList = [];
  if (child !== undefined) {
    let recursiveObject = (subObject, fatherKey = 'Mobifone') => {
      let _arr = [];
      Object.keys(subObject).map((e, i) => {
        if (subObject[e]) _arr.push(e);
        else _arr.push(e + '_end');
        if (Object.keys(subObject[e]).length > 0) {
          recursiveObject(subObject[e], e);
        }
      });
      cssTransitionList.push({
        [fatherKey]: _arr
      });
    };
    recursiveObject(child);
  }
  return cssTransitionList;
};

export function reportName(obj) {
  if (obj) {
    var output = obj.substring(obj.indexOf('.') + 1);
    output = output.toUpperCase();
    return output;
  }
}

export function reportUnitName(type, sub, grandSub) {
  var name = '';
  if (type) name += type;
  if (sub) name += ' - ' + sub;
  if (grandSub) name += ' - ' + grandSub;
  return name;
}

var hasOwnProperty = Object.prototype.hasOwnProperty;

export function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (var key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export function romanize(num) {
  var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 }, roman = '', i;
  for (i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

export function Arrow(props) {
  if (props.Type === 'GreenUp') {
    switch (props.GiaTriTrend) {
      case 1:
        return (
          <span>
            <i
              className="fas fa-arrow-up fa-sm d-inline ml-1 text-success float-left ptx-3"
              style={{ color: '#1dc9b7' }}
            ></i>
            {props.GiaTri?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        );
      case 0:
        return (
          <span>
            <i
              className="fas fa-arrow-right fa-sm d-inline ml-1 text-warning float-left ptx-3"
              style={{ color: '#ffb822' }}
            ></i>
            {props.GiaTri?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        );
      case -1:
        return (
          <span>
            <i
              className="fas fa-arrow-down fa-sm d-inline ml-1 text-danger float-left ptx-3"
              style={{ color: '#fd397a' }}
            ></i>
            {props.GiaTri?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        );
      default:
        return <span></span>;
    }
  } else {
    switch (props.GiaTriTrend) {
      case 1:
        return (
          <span>
            <i
              className="fas fa-arrow-up fa-sm d-inline ml-1 text-danger float-left ptx-3"
              style={{ color: '#fd397a' }}
            ></i>
            {props.GiaTri?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        );
      case 0:
        return (
          <span>
            <i
              className="fas fa-arrow-right fa-sm d-inline ml-1 text-warning float-left ptx-3"
              style={{ color: '#ffb822' }}
            ></i>
            {props.GiaTri?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        );
      case -1:
        return (
          <span>
            <i
              className="fas fa-arrow-down fa-sm d-inline ml-1 text-success float-left ptx-3"
              style={{ color: '#1dc9b7' }}
            ></i>
            {props.GiaTri?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </span>
        );
      default:
        return <span></span>;
    }
  }
}

export function kpiCase(menu) {
  switch (menu.menulv1) {
    case 'TTML':
      if (!menu.menulv3 && !menu.menulv2) {
        return 'Tỉnh ';
      }
      if (menu.menulv2 && !menu.menulv3) {
        return 'TVT ';
      }

      if (menu.menulv3 && menu.menulv2) {
        return 'Huyện ';
      }
      break;
    case 'CTKD':
      if (!menu.menulv3 && !menu.menulv2) {
        return 'Chi Nhánh ';
      }
      if (menu.menulv2 && !menu.menulv3) {
        return 'Huyện ';
      }

      if (menu.menulv2 && menu.menulv3) {
        return 'Huyện ';
      }
      break;
    case 'VDL':
      if (!menu.menulv3 && !menu.menulv2) {
        return 'Tỉnh ';
      }
      if (menu.menulv2 && !menu.menulv3) {
        return 'Huyện ';
      }

      if (menu.menulv2 && menu.menulv3) {
        return 'Huyện ';
      }
      break;
    default:
      return 'Tỉnh ';
  }
}

export function kFormatter(num) {
  if (num === 0) return '';
  // return num.Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) : Math.sign(num) * Math.abs(num);
  return num?.toFixed(2);
}

export function kNumber(num) {
  if (num) {
    return num.toLocaleString('en-US');
  }
  if (num === 0) {
    return 0;
  } else {
    return '';
  }
}

export function kFormatterData(num, string = '') {
  if (num === '*****') {
    return (
      <Tooltip2 className={Classes.TOOLTIP_INDICATOR} content={jsxContent}>
        {num}
      </Tooltip2>
    );
  }
  if (typeof (num) === 'string') return num;
  if (num === 0) return 0;
  if (!num) return '';
  const number = +num;
  let result = number?.toLocaleString(undefined, { maximumFractionDigits: 2 });
  return result != undefined ? result : "";
}
export function Dive(TenKPI) {
  let num = 1;
  switch (TenKPI) {
    default:
      break;
    case "CACHE_IXP_TRANSIT":
    case "CACHE_IXP_PEERING":
    case "POP_PEERING_TRAFFIC":
    case "VAS_IN_CNTT_INTERNET":
    case "CACHE_VTVCAB":
    case "TRAFFIC_FTTX":
    case "General THP":
    case "VLR_M2M_SUB_2G":

    case "Luu Luong Thoai Erl":
    case "VLR_SUBS_TOTAL_INCLUDE_UNKNOWN":
      num = 1000;
      break;
    case "Luu Luong Data GB":
      num = 1000000000;
      break;
    case "Số lượng tin nhắn SMS":
      num = 1000000;
      break;

  }
  return num;
}
export function DiveThueBao(TenKPI) {
  let num = 1;
  switch (TenKPI) {
    default:
      break;
    case "VLR_SUBS_TOTAL_INCLUDE_UNKNOWN":
    case "VLR_VOLTE":
    case "VoWifi":
    case "VLR_2G":
    case "VLR_3G":
    case "VLR_4G":
    case "VLR_M2M_SUB":
    case "VLR_5G":
    case "DATA_TOTAL":
    case "ATTACHEDSUB 2G":
    case "ATTACHEDSUB 3G":
    case "ATTACHEDSUB 4G":
    case "VLR_SIM4G":
    case "4G_SUPPORT":
    case "VLR_SUBS_5G_SUPPORT":
    case "VLR_SIM4G_HANDSET4G":
    case "TB_FTTX":
    case "So_thue_bao_mbf_phat_trien":
    case "So_thue_bao_vtv_cab":
    num = 1000;
    break;
  }
  return num;
}
export function Chuyendoichu(Noc)
{
  let noc = Noc;
  switch (Noc) {
    default:
      break;
    case "Mien Bac":
      noc = 'Miền Bắc';
      break;

    case "Mien Nam":
      noc = 'Miền Nam';
      break;
    case "Mien Trung":
      noc = 'Miền Trung';
      break;

    case "Mobifone":
      noc = 'Toàn mạng';
    break;
  }
  return noc;
}

export function kFormatterThousandData(num) {
  if (num === '*****') {
    return (
      <Tooltip2 className={Classes.TOOLTIP_INDICATOR} content={jsxContent}>
        {num}
      </Tooltip2>
    );
  }
  if (typeof (num) === 'string') return num;
  if (num === 0) return 0;
  if (!num) return '';
  const number = +num;
  return number?.toLocaleString(undefined, { maximumFractionDigits: 2 });
}
export function kFormatterRoundMilionNumber(num) {
  if (num === '*****') {
    return (
      <Tooltip2 className={Classes.TOOLTIP_INDICATOR} content={jsxContent}>
        {num}
      </Tooltip2>
    );
  }
  if (num === 0) return 0;
  if (!num) return '';
  num = num / 1000000;
  const number = +num;
  return Math.round(number);
}
export function formatNumberSeparator(num) {
  if (num === '*****') {
    return (
      <Tooltip2 className={Classes.TOOLTIP_INDICATOR} content={jsxContent}>
        {num}
      </Tooltip2>
    );
  }
  if (typeof (num) === 'string') return num;
  if (!num) return '';
  const number = +num;
  const spanishNumberFormatter = new Intl.NumberFormat('es-ES');
  return spanishNumberFormatter.format(number?.toFixed(2));
}

export function getDataNameByTime(time) {
  switch (time) {
    default:
    case 'day':
      return {
        nameGiaTriTrend: 'SoVoiHomTruocTrend',
        nameGiaTri: 'SoVoiHomTruoc'
      };
    case 'week':
      return {
        nameGiaTriTrend: 'SoVoiTuanTruocTrend',
        nameGiaTri: 'SoVoiTuanTruoc'
      };
    case 'month':
      return {
        nameGiaTriTrend: 'SoVoiThangTruocTrend',
        nameGiaTri: 'SoVoiThangTruoc'
      };
    case 'quarter':
      return {
        nameGiaTriTrend: 'SoVoiQuyTruocTrend',
        nameGiaTri: 'SoVoiQuyTruoc'
      };
    case 'year':
      return {
        nameGiaTriTrend: 'SoVoiNamTruocTrend',
        nameGiaTri: 'SoVoiNamTruoc'
      };
  }
}

export const pathToUrl = (bcctURL) => {
  let urlArr = bcctURL.Path.replace('/noc-sopirs/', '').replace('/Chuyên sâu/', '').split('/');
  if (bcctURL.Id === 'dim-tram') {
    urlArr = 'TRUYỀN DẪN/Cập nhật BW Trạm'.split('/');
  }
  if (bcctURL.Id === '"dim-ip-core"') {
    urlArr = 'TRUYỀN DẪN/Cập nhật link IPCORE-IPRAN'.split('/');
  }
  if (bcctURL.TypeName === 'PowerBI') {
    if (bcctURL?.Description) {
      urlArr = bcctURL?.Description?.split('/');
    }
  }

  return urlArr;
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;


const mapStateToProps = (state) => ({
  menulevel: state.updateRouterLevel.menulevel,
  bcctURL: state.updateTabs.activeTab
});