import React from 'react';
import { Intent, Tag } from '@blueprintjs/core';

export function getLastItemStringURL(url) {
  const array = url.split('/');

  return array[array.length - 1];
}
export function toReportName(obj) {
  if (obj) {
    let output = obj.substring(obj.indexOf('.') + 1);
    output = output.toUpperCase();
    return output;
  }
}
  
export function stringToSlug(str) {
  // remove accents
  var from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
      to   = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i=0, l=from.length ; i < l ; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str.toLowerCase()
        .trim()
        .replace(/[^a-z0-9\-]/g, '-')
        .replace(/-+/g, '-');

  return str;
}

export function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getDate(date= new Date()) {
  let day = date.getDate();
  let month = getMonthOfYear();
  let year = getYear();
  let result = `Ngày ${day} Tháng ${month} Năm ${year}`;
  return result;
}
export function getWeekOfYear(date= new Date()) {
  let currentdate = new Date();
  let oneJan = new Date(currentdate.getFullYear(), 0, 1);
  let numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  let result = Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
  return result;
}
export function getMonthOfYear(date= new Date()) {
  var d = date;
  var n = d.getMonth();
  return n + 1;
}
export function getQuatorOfYear(date= new Date()) {
  var today = date;
  var quarter = Math.floor((today.getMonth() + 3) / 3);
  return quarter;
}
export function getYear(date= new Date()) {
  var d = date;
  var n = d.getFullYear();
  return n;
}
const TimeTags = ({ children }) => (
  <Tag intent={Intent.PRIMARY} icon={'time'} fill>
    {children}
  </Tag>
);

export function getTagTime(type,date) {
  switch (type) {
    default:
      return;
    case 'day':
      return <TimeTags>Dữ liệu {getDate(date)}</TimeTags>;
    case 'week':
      return <TimeTags>Dữ liệu tuần {getWeekOfYear(date)}</TimeTags>;
    case 'month':
      return <TimeTags>Dữ liệu tháng {getMonthOfYear(date)}</TimeTags>;
    case 'quarter':
      return <TimeTags>Dữ liệu Quí {getQuatorOfYear(date)}</TimeTags>;
    case 'year':
      return <TimeTags>Dữ liệu năm {getYear(date)}</TimeTags>;
  }
}
