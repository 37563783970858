/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import * as auth from '../_redux/authAction';
import { login } from '../_redux/authCrud';
import { Alignment, Checkbox } from '@blueprintjs/core';
import { Card } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import OverlayBlocking from './OverlayBlocking';
import OverlayReturmMainPage from './OverlayReturmMainPage';
import { getCookie, setCookie } from 'app/helper/utilities';
import { toAbsoluteUrl } from 'app/helper/filter';
import { _GET } from 'app/services/apiCrud';
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/
let toggleLogin = getCookie('toggleLogin');
let usernameLogin = getCookie('userNameLogin');
let passwordLogin = getCookie('passwordLogin');
const initialValues = {
  username: !usernameLogin || usernameLogin === 'null' ? '' : usernameLogin,
  password: !passwordLogin || passwordLogin === 'null' ? '' : passwordLogin
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [isChecked, setisChecked] = useState(
    !toggleLogin || toggleLogin === 'null' || toggleLogin === 'false' ? false : true
  );
  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Trường tài khoản không thể bỏ trống'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Trường mật khẩu không thể bỏ trống')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      document.cookie = `toggleLogin=${isChecked};`;
      if (isChecked) {
        document.cookie = `userNameLogin=${values.username};`;
        document.cookie = `passwordLogin=${values.password};`;
      } else {
        document.cookie = `userNameLogin=null;`;
        document.cookie = `passwordLogin=null;`;
      }
      enableLoading();
      setTimeout(() => {
        login(values.username, values.password)
          .then(async ({data}) => {
            disableLoading();
            console.log(data);
        
            localStorage.setItem('ls.authorization', JSON.stringify(data));
            localStorage.setItem('token', data['access_token']);
            // localStorage.setItem('UserName', data?.UserName);
            localStorage.setItem('UserName', "Chưa có tên");
            let responseUserInfo =  await _GET('account/my-profile');
            // let response =  await _GET('get-quyen-menu-of-user');
            // let responseCookie = await _GET('new-web-menu-chi-tiet-cookies');
            // document.cookie = '.ASPXAUTH=' + responseCookie.data.m_value + ';path=/;';
            data['.expires'] = "";
            data['.issued'] = "";
            data.Email = responseUserInfo.data.Email;
            data.FullName = responseUserInfo.data.Name;
            data.GroupName = "";
            data.OTPActive = "";
            data.PhoneNumber = responseUserInfo.data.PhoneNumber;
            data.UserGroup = "";
            data.UserId = "";
            data.UserName = responseUserInfo.data.UserName;
            data['as:client_id'] = "";
            await props.login({...data,...{listMenusAlow:[]}});
            // await props.login({...data,...{listMenusAlow:[]}});
          })
          .catch((error) => {
            disableLoading();
            setSubmitting(false);
            setStatus(error?.response?.data?.error);
          });
      }, 1000);
    }
  });

  return (
    <Card
      style={{
        padding: '5px',
        boxShadow: '0 0 7px rgba(0, 0, 0, 0.175)'
      }}
      id="kt_login_signin_form"
    >
      <Card.Img
        variant="top"
        alt="SmartF"
        src={toAbsoluteUrl('/media/logos/login_img.jpg')}
        width="100%"
        height="85px"
        style={{
          paddingBottom: '5px'
        }}
      />{' '}
      {/*begin::Form*/}
      {isMobileOnly && <OverlayBlocking />}
      {/* {location.hostname !== "smartf.mobifone.vn" && <OverlayReturmMainPage />} */}
      <form onSubmit={formik.handleSubmit}>
        {formik.status ? (
          <Card.Title className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </Card.Title>
        ) : (
          <></>
        )}
        <div className="row m-10">
          <div className="col-12">
            <div className="row">
              <div className="col-8">
                <label htmlFor="username" className="col-4 control-label">
                  Tài khoản :
                </label>
                <input
                  style={{
                    boxShadow: '0 0 7px rgba(0, 0, 0, 0.175)',
                    border: '0px'
                  }}
                  placeholder="UserName"
                  type="text"
                  className={`col-8 px-9 py-4 my-3
                                    ${getInputClasses('username')}`}
                  name="username"
                  {...formik.getFieldProps('username')}
                />{' '}
                {formik.touched.username && formik.errors.username ? (
                  <div
                    className="fv-plugins-message-container"
                    styles={{ color: 'red' }}
                  >
                    <div className="fv-help-block">{formik.errors.username}</div>
                  </div>
                ) : null}
              </div>
              <div className="checkbox col-4">
                <Checkbox
                  alignIndicator={Alignment.LEFT}
                  defaultChecked={isChecked}
                  onChange={(e) => {
                    setisChecked(e.target.checked);
                  }}
                  label="Ghi nhớ tài khoản"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                <label htmlFor="password" className="col-4 control-label">
                  Mật khẩu :
                </label>
                <input
                  style={{
                    boxShadow: '0 0 7px rgba(0, 0, 0, 0.175)',
                    border: '0px'
                  }}
                  placeholder="Password"
                  type="password"
                  className={`col-8 px-9 py-4 my-3
                                    ${getInputClasses('password')}`}
                  name="password"
                  {...formik.getFieldProps('password')}
                />{' '}
                {formik.touched.password && formik.errors.password ? (
                  <div
                    className="fv-plugins-message-container"
                    styles={{ color: 'red' }}
                  >
                    <div className="fv-help-block">{formik.errors.password}</div>
                  </div>
                ) : null}
              </div>
              <div className="col-4">
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  className={`btn btn-primary font-weight-bold col-12
                                    px-9 py-4 my-3`}
                >
                  {/* <i className={`${formik.isSubmitting ? 'd-none' :''} flaticon-add-label-button
                                        m-r-10 t-1 p-relative`}></i> */}
                  <span>Đăng nhập</span>
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*end::Form*/}
      <div className="row">
        <div
          className="col-md-4"
          style={{
            height: '10px',
            background: 'red'
          }}
        ></div>
        <div
          className="col-md-4"
          style={{
            height: '10px',
            background: 'yellow'
          }}
        ></div>
        <div
          className="col-md-4"
          style={{
            height: '10px',
            background: 'steelblue'
          }}
        ></div>
      </div>
    </Card>
  );
}

export default connect(null, auth.actions)(Login);
